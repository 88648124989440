import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sanitize } from 'dompurify';
import {
  Link, Spinner, ListLinks, Icon,
} from 'bv-components';
import { useFetch } from 'bv-hooks';
import withLazyLoad from 'bv-lazy-load';
import { t } from 'bv-i18n';
import { fetchStaticPage } from './api';
import LinkItem from './components/link_item';
import CustomSection from './components/custom_section';
import { getUrlWithoutLocale, attachOneTrustCTA } from './helpers';

const ErrorPage = withLazyLoad(<Spinner />)('errorPage');

const StaticPagesView = () => {
  const location = useLocation();
  const [pageData, loading] = useFetch(() => fetchStaticPage(location.pathname), [location]);

  useEffect(() => {
    if (pageData) attachOneTrustCTA();
  }, [pageData]);

  if (loading) {
    return <Spinner />;
  }

  if (pageData && pageData.error) {
    return <ErrorPage />;
  }

  return (
    <article className="page-contents-wrapper">
      <div className="page-contents">
        {pageData.parent_full_url && (
          <Link
            to={getUrlWithoutLocale(pageData.parent_full_url)}
            className="back-link"
          >
            <Icon className="is-arrow-left" />
            {t('back')}
          </Link>
        )}
        <h1 className="bvs-header bvs-h4">{pageData.title}</h1>
        <div
          className="bvs-card"
          dangerouslySetInnerHTML={
            {
              __html: sanitize(pageData.body,
                { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }),
            }
          }
        />
      </div>
      {pageData && pageData?.custom_section < 0 && (
        <CustomSection fakeSportId={pageData.custom_section} />
      )}
      {pageData.children && pageData.children.length ? (
        <ListLinks>
          {pageData.children.map((link) => <LinkItem link={link} key={link.full_url} />)}
        </ListLinks>
      ) : null}
    </article>
  );
};

export default StaticPagesView;
