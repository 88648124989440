import { Link } from 'bv-components';
import PropTypes from 'prop-types';
import { getUrlWithoutLocale } from '../helpers';

const LinkItem = ({ link }) => (
  <li>
    {link.full_url.startsWith('http') ? (
      <a rel="noreferrer" href={link.full_url}>
        {link.title}
      </a>
    ) : (
      <Link to={getUrlWithoutLocale(link.full_url)}>{link.title}</Link>
    )}
  </li>
);

LinkItem.propTypes = {
  link: PropTypes.instanceOf(Object).isRequired,
};

export default LinkItem;
