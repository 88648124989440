import { ContentLayout } from 'bv-components';
import StaticPagesView from './static_pages_view';

const StaticPagesContainer = () => (
  <ContentLayout>
    <StaticPagesView />
  </ContentLayout>
);

export default StaticPagesContainer;
