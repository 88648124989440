export const getUrlWithoutLocale = (fullUrl) => fullUrl.substring(fullUrl.indexOf('/', 1));

export const attachOneTrustCTA = () => {
  if (window.OneTrust) {
    const links = document.querySelectorAll('.OneTrust-ToggleInfoDisplay');
    links.forEach((link) => {
      link.addEventListener('click', () => {
        window.OneTrust.ToggleInfoDisplay();
      });
    });
  }
};
