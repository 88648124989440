import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export const fetchStaticPage = (path) => getJSON(`${path}.json`)
  .catch(() => ({ error: true }));

export const fetchComponents = (fakeSportId, loggedIn) => (
  getJSON('/sportsbook_components/home_components/components', {
    c: locale(),
    l: loggedIn,
    sport_id: fakeSportId,
  }).catch(() => ([]))
);
