import PropTypes from 'prop-types';
import withLazyLoad from 'bv-lazy-load';
import { useFetch, useLoggedIn } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { fetchComponents } from '../api';

const HomeComponentsLazy = withLazyLoad()('homeComponents');

const CustomSection = ({ fakeSportId }) => {
  const loggedIn = useLoggedIn();
  const [components, loading] = useFetch(
    () => fetchComponents(fakeSportId, loggedIn), [fakeSportId, loggedIn],
  );

  return loading
    ? <Spinner />
    : (components?.length > 0 && <HomeComponentsLazy components={components} />);
};

CustomSection.propTypes = {
  fakeSportId: PropTypes.number.isRequired,
};

export default CustomSection;
